import React from 'react';
import { NavigateOptions, useLocation } from '@reach/router';

import { useNavigate } from '@lib/routing';
import { useCurrentUser } from '@modules/user/hooks';

import type { UserRole } from '@modules/types/graphql';

export type TabNavigation = {
    hash: string;
    label: string;
    Component: (...args: any[]) => React.ReactElement;
    roles?: UserRole[];
};

type Options = {
    tabs: TabNavigation[];
};

const useTabsNavigation = (options: Options) => {
    const { tabs } = options;

    const location = useLocation();
    const baseNavigate = useNavigate();

    const { currentUser, loading: currentUserLoading } = useCurrentUser();
    const currentRole = currentUser.getCurrentRole();

    const currentTabIdx = tabs.findIndex(tab => tab.hash === location.hash);

    const navigate = React.useCallback(
        (url: string, options: NavigateOptions<{}> = {}) =>
            baseNavigate(url, { replace: true, ...options }),
        [baseNavigate],
    );

    const handleChangeTab = (_: React.ChangeEvent<{}>, tab: number): void => {
        const tabLink = location.pathname + tabs[tab].hash;

        navigate(tabLink);
    };

    React.useEffect(() => {
        const linkState = { ...(location.state as Record<string, any> | null) };
        const hash = linkState.hash;

        if (hash) {
            navigate(hash);
        }
    }, [location]);

    React.useEffect(() => {
        if (currentTabIdx === -1) {
            navigate(tabs[0].hash);
        }
    }, [currentTabIdx]);

    const navigationTabs = tabs.filter(tab => currentRole && tab.roles?.includes(currentRole));
    const currentNavigationTab = navigationTabs[currentTabIdx];

    const hasNavigationTabComponent = currentRole && !currentUserLoading && currentNavigationTab;

    const result = {
        navigationTabs,
        currentNavigationTab: currentTabIdx === -1 ? 0 : currentTabIdx,
        CurrentNavigationTabComponent: hasNavigationTabComponent
            ? currentNavigationTab.Component
            : null,

        handleChangeTab,
    };

    return result;
};

export { useTabsNavigation };
