import React from 'react';

import { TokenSession } from '@lib/token';
import { useNavigate, changePlatform, Redirect } from '@lib/routing';
import { routes } from '@config/routes';
import { useCurrentUser } from '@modules/user/hooks';
import { useHasAccessForPlatform } from '@modules/auth/hooks';

import type { Route } from '@config/routes';

const PrivateRoute = (props: Route): React.ReactElement | null => {
    const {
        redirectTo,
        categoryGrant,
        categoryGrantFor,
        roles,
        renderFor,
        titleFor,
        Component,
        LayoutComponent,
        ...otherProps
    } = props;

    const navigate = useNavigate();

    const {
        currentUser,
        loading: userLoading,
        error: userError,
    } = useCurrentUser({ skip: !TokenSession.getCurrentSession().hasTokens() });

    const role = currentUser.getCurrentRole();

    useHasAccessForPlatform({
        fetchPolicy: 'network-only',
        onError: () => {
            navigate(routes.error.path);
        },
        onCompleted: data => {
            if (!data.hasAccessForPlatform) {
                navigate(routes.error.path);
            }
        },
    });

    React.useEffect(() => {
        if (redirectTo) {
            navigate(redirectTo, { replace: true });
        }
    }, []);

    React.useEffect(() => {
        if (!userLoading && (userError || !currentUser.authenticated())) {
            TokenSession.destroyCurrentSession();
            changePlatform();
        }
    }, [currentUser, userLoading, userError]);

    if (!currentUser.authenticated() || userError || userLoading) {
        return null;
    }

    if (categoryGrant) {
        let needCategoryGrant = categoryGrant;
        if (role && categoryGrantFor?.[role]) {
            needCategoryGrant = categoryGrantFor[role] as typeof categoryGrant;
        }

        if (!currentUser.hasAccessByGrants(needCategoryGrant)) {
            return <Redirect noThrow to={routes.index.path} />;
        }
    }

    if (roles) {
        if (!currentUser.hasAccessByRoles(roles)) {
            return <Redirect noThrow to={routes.index.path} />;
        }
    }

    let pageTitle = otherProps.title;

    if (role && titleFor?.[role]) {
        pageTitle = titleFor[role] ?? otherProps.title;
    }

    if (role && renderFor?.hasOwnProperty(role)) {
        const RoleComponent = renderFor[role];

        return (
            <LayoutComponent pageTitle={pageTitle} withBackIcon={otherProps.withBackIcon}>
                {RoleComponent ? <RoleComponent {...otherProps} /> : null}
            </LayoutComponent>
        );
    }

    return (
        <LayoutComponent pageTitle={pageTitle} withBackIcon={otherProps.withBackIcon}>
            {Component && !redirectTo ? <Component {...otherProps} /> : null}
        </LayoutComponent>
    );
};

export { PrivateRoute };
