import { gql } from '@apollo/client';

import { LoginReplyFragment } from '@modules/auth/graphql';

export const ChangePasswordMutation = gql`
    mutation ChangePassword($old: String!, $new: String!) {
        changePassword(old: $old, new: $new) {
            ...LoginReply
        }
    }
    ${LoginReplyFragment}
`;
