import React from 'react';
import styled from 'styled-components';
import InfiniteScrollComponent from 'react-infinite-scroll-component';

import type { Props as InfiniteScrollComponentProps } from 'react-infinite-scroll-component';

type InfiniteScrollProps = Omit<InfiniteScrollComponentProps, 'loader'> & {
    loader?: React.ReactNode;
};

const Container = styled.div`
    overflow: hidden;
    height: 100%;
    width: 100%;

    & > * {
        overflow: inherit;
        height: inherit;
        width: inherit;

        & > * {
            width: 100% !important;
            height: 100% !important;
            overflow-y: scroll !important;
            padding-inline-end: 17px !important;
            box-sizing: content-box !important;
        }
    }
`;

const InfiniteScroll = (props: InfiniteScrollProps): React.ReactElement => {
    const { children, loader, ...otherProps } = props;

    return (
        <Container>
            <InfiniteScrollComponent {...otherProps} loader={loader}>
                {children}
            </InfiniteScrollComponent>
        </Container>
    );
};

export { InfiniteScroll };
