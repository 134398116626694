import styled from 'styled-components';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import {
    Button,
    DatepickerSinglePicker,
    DatepickerTextField,
    OnDateChangeProps,
    TextField,
} from '@avangard/ui/core';
import { MenuItem } from '@material-ui/core';

import { useEnqueueStacks } from '@modules/layout/hooks';
import { FormStyled } from '@modules/layout/styled';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { programTypes } from '@modules/lap/constants';
import { UpdateLapRequest } from '@modules/lap/requests';
import { UpdateLapMutation, GetLapCollectionQuery, GetLapsQuery } from '@modules/lap/graphql';

import type { LapEntity } from '@modules/lap/entities';
import type { UpdateLapFormValues } from '@modules/lap/requests';
import type { UpdateLapMutationType, UpdateLapMutationVariables } from '@modules/types/graphql';

type LapCreateFormProps = {
    canEdit: boolean;
    lap: LapEntity;
    onCancel: () => void;
};

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 24px;
        }
    }
`;

const FormRowSubmit = styled(FormStyled.FormRowSubmit)`
    justify-content: flex-end;
`;

const LapEditForm = (props: LapCreateFormProps) => {
    const { canEdit, lap, onCancel } = props;

    const { t } = useTranslation(['common', 'errors', 'lap']);

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const [updateLap] = useMutation<UpdateLapMutationType, UpdateLapMutationVariables>(
        UpdateLapMutation,
    );

    const initialValues: UpdateLapFormValues = {
        programType: lap.programType ?? '',
        from: lap.getDate(lap.from),
        to: lap.getDate(lap.to),
    };

    return (
        <ExtendedFormik
            validationSchema={yup.object({
                programType: yup.string().required(t('errors:validations.required') ?? ''),
                from: yup
                    .date()
                    .nullable()
                    .required(t('errors:validations.required') ?? ''),
                to: yup
                    .date()
                    .nullable()
                    .required(t('errors:validations.required') ?? '')
                    .min(yup.ref('from'), t('errors:time.invalid_range') ?? ''),
            })}
            initialValues={initialValues}
            onSubmit={async values => {
                try {
                    const item = new UpdateLapRequest(values);

                    const { data: updateLapData } = await updateLap({
                        variables: { id: lap.id, item },
                        refetchQueries: [{ query: GetLapCollectionQuery }, { query: GetLapsQuery }],
                        awaitRefetchQueries: true,
                    });

                    const lapId = updateLapData?.updateLap?.id;

                    if (lapId) {
                        enqueueSuccess(t('lap:notifiers.edit_lap.success'));
                        onCancel();
                    } else {
                        enqueueError(t('lap:notifiers.edit_lap.error'));
                    }
                } catch (e) {
                    console.log(e);
                    throw e;
                }
            }}
        >
            {formikProps => {
                const { values, errors, isSubmitting, setFieldValue, handleChange } = formikProps;

                const handleChangeDate = (field: string) => (data: OnDateChangeProps) =>
                    setFieldValue(field, data.date);

                return (
                    <StyledForm>
                        <TextField
                            select
                            fullWidth
                            id='programType'
                            name='programType'
                            label={t('common:forms.labels.program_type')}
                            placeholder={t('common:forms.placeholder.choose_program_type') ?? ''}
                            disabled={!canEdit}
                            value={values.programType}
                            error={!!errors.programType}
                            helperText={errors.programType}
                            onChange={handleChange}
                        >
                            <MenuItem disabled value=''>
                                {t('common:forms.placeholder.choose_program_type')}
                            </MenuItem>

                            {programTypes.map(programType => (
                                <MenuItem key={programType.key} value={programType.key}>
                                    {t(programType.value)}
                                </MenuItem>
                            ))}
                        </TextField>

                        <DatepickerSinglePicker
                            date={values.from}
                            onDateChange={handleChangeDate('from')}
                        >
                            {datepickerProps => {
                                const { inputProps, inputComponent } = datepickerProps;

                                return (
                                    <DatepickerTextField
                                        id='from'
                                        label={t('common:forms.labels.start_lap_date')}
                                        placeholder={t('common:forms.placeholder.date') ?? ''}
                                        disabled={!canEdit}
                                        error={!!errors.from}
                                        helperText={errors.from}
                                        InputProps={{ inputComponent }}
                                        {...inputProps}
                                    />
                                );
                            }}
                        </DatepickerSinglePicker>

                        <DatepickerSinglePicker
                            date={values.to}
                            onDateChange={handleChangeDate('to')}
                        >
                            {datepickerProps => {
                                const { inputProps, inputComponent } = datepickerProps;

                                return (
                                    <DatepickerTextField
                                        id='to'
                                        label={t('common:forms.labels.end_lap_date')}
                                        placeholder={t('common:forms.placeholder.date') ?? ''}
                                        disabled={!canEdit}
                                        error={!!errors.to}
                                        helperText={errors.to}
                                        InputProps={{ inputComponent }}
                                        {...inputProps}
                                    />
                                );
                            }}
                        </DatepickerSinglePicker>

                        <FormRowSubmit>
                            <Button variant='outlined' disabled={isSubmitting} onClick={onCancel}>
                                {t('common:actions.cancel')}
                            </Button>

                            {canEdit ? (
                                <Button type='submit' loading={isSubmitting}>
                                    {t('common:actions.force_change')}
                                </Button>
                            ) : null}
                        </FormRowSubmit>
                    </StyledForm>
                );
            }}
        </ExtendedFormik>
    );
};

export { LapEditForm };
